<template>
  <div class="activityStatistics flex-column-between">
    <div class="Mian flex-column-between">
      <div class="statisticalItems flex-align-between">
        <!-- <div class="Participation flex-align-center">
          <img src="../../assets/surfaceHome/ro.png" />
        </div> -->
        <div class="category flex-align-evenly">
          <div class="categoryList flex-column-center" v-for="(item, index) in categoryList" :key="index">
            <img :src="item.icon" alt="" />
            <div class="nums" :style="{ color: item.color }">
              <div class="nums1">{{item.nums}}</div>
              <!-- <div class="nums2">个</div> -->
            </div>
            <div class="categoryName">{{ item.categoryName }}</div>
          </div>
        </div>
      </div>
      <div class="activityTable flex-column-between">
        <div class="activity_box flex-align" :class="{'flex-align-between':childActive.length==3}">
          <div class="activityItem" v-for="(item, index) in childActive" :key="index"
            @click="gostatisticalDetails(item.shareCode)">
            <div class="ItemBg"></div>
            <div class="ItemData">
              <div class="ItemName">{{item.name}}</div>
              <ul>
                <li>
                  <div>参与人数：</div>
                  <div>{{item.activityMemberCount}}人</div>
                </li>
                <li>
                  <div>发帖数：</div>
                  <div>{{item.postCount}}次</div>
                </li>
                <li>
                  <div>回复数：</div>
                  <div>{{item.replyCount}}次</div>
                </li>
                <li>
                  <div>作品数：</div>
                  <div>{{item.workCount}}个</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="Tablepaging flex-align-center">
          <el-pagination background @current-change="handleCurrentChange" :current-page="currentPage4" :page-size="8"
            layout="total, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="Footer"></div>
  </div>
</template>

<script>
  export default {
    name: "",
    components: {},
    data() {
      return {
        categoryList: [{
            icon: require("../../assets/surfaceHome/member.png"),
            nums: "10",
            categoryName: "活动成员",
            color: "#689CFC",
          },
          {
            icon: require("../../assets/surfaceHome/Subactivities.png"),
            nums: "10",
            categoryName: "任务数",
            color: "#4EDDCB",
          },
          {
            icon: require("../../assets/surfaceHome/Numberofworks.png"),
            nums: "10",
            categoryName: "作品数",
            color: "#F6B926",
          },
          {
            icon: require("../../assets/surfaceHome/reply.png"),
            nums: "10",
            categoryName: "发帖/回复数",
            color: "#6CDD66",
          },
        ],
        currentPage4: 1,
        childActive: [],
        total:0
      };
    },
    methods: {
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
      },
      gostatisticalDetails(shareCode) {
        this.$router.push({
          path: "/statisticsHome/statisticalDetails",
          query:{shareCode}
        })
      },
      // 获取活动统计数据
      async getActiveData() {
        let data = {
          page: 1,
          limit: 3
        }
        let resData = await this.$Api.Manage.getStatistics(data)
        console.log("活动统计数据--", resData)
        this.total = resData.data.total
        this.$set(this.categoryList, 0, {
          ...this.categoryList[0],
          nums: resData.data.activityMemberCount + '个'
        })
        this.$set(this.categoryList, 1, {
          ...this.categoryList[1],
          nums: resData.data.total + '个'
        })
        this.$set(this.categoryList, 2, {
          ...this.categoryList[2],
          nums: resData.data.workCount
        })
        this.$set(this.categoryList, 3, {
          ...this.categoryList[3],
          nums: `${resData.data.postCount}/${resData.data.replyCount}次`
        })
        this.childActive = resData.data.subTeachActivityCounts
      }
    },
    mounted() {
      this.getActiveData()
    },
  };
</script>
<style lang="less" scoped>
  @import "./less/activityStatistics.less";
</style>
<style>
  .Tablepaging .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #21bd99 !important;
    color: #fff !important;
  }

  .Tablepaging .el-pagination.is-background .el-pager li:hover {
    color: #21bd99 !important;
  }

  .Tablepaging .el-pager li.active {
    color: #21bd99 !important;
  }

  .Tablepaging .el-input__inner:focus {
    border-color: #21bd99;
  }
</style>